import React from 'react';
import { Flex, Text, Img } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import SeparatorVertical from '../../components/SeparatorVertical';
import useObserve from '../../hooks/useInView';

const styleContainer = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgColor: 'brand.secondaryBackground',
  borderRadius: '3xl',
  width: '100%',
  maxW: 81,
  h: 48,
  p: 4,
};

const styleInput = {
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  bgColor: 'white',
  boxShadow: '0px 4px 30px 0px #0000000d',
  borderRadius: 'lg',
  px: 5,
  py: 4,
  width: '100%',
  maxW: 66,
  h: 33,
};

const stylePersonInfo = {
  fontWeight: 'semibold',
  alignItems: 'center',
};

const styleInputMessageOne = {
  flexDirection: 'column',
  justifyContent: 'center',
  bgColor: 'white',
  border: '1px solid',
  borderColor: 'gray.200',
  boxShadow: 'sm',
  borderRadius: '2xl',
  borderBottomLeftRadius: 'none',
  pos: 'absolute',
  fontSize: 'sm',
  opacity: 0,
  px: 5,
  py: 4,
  h: 10,
};

const styleInputMessageTwo = {
  flexDirection: 'column',
  justifyContent: 'center',
  bgColor: 'white',
  border: '1px solid',
  borderColor: 'gray.200',
  boxShadow: 'sm',
  borderRadius: '2xl',
  borderBottomLeftRadius: 'none',
  pos: 'absolute',
  fontSize: 'sm',
  opacity: 0,
  px: 5,
  py: 4,
  h: 10,
};

const HowWorkStepThree = ({ isMobile }) => {
  const { t } = useTranslation('common');
  const { ref, inView } = useObserve();

  if (inView) {
    if (isMobile) {
      stylePersonInfo.animation = 'person-info 7s ease 0.4s infinite';
      styleInputMessageOne.animation = 'fadeIn-bottom 7s ease 0s infinite';
      styleInputMessageTwo.animation = 'fadeIn-bottom 7s ease 1.5s infinite';
      styleContainer.animation =
        'flashing-shadow-step-three 7s ease 4s infinite';
    } else {
      stylePersonInfo.animation = 'person-info 10s ease 7s infinite';
      styleInputMessageOne.animation = 'fadeIn-bottom 10s ease 6s infinite';
      styleInputMessageTwo.animation = 'fadeIn-bottom 10s ease 8s infinite';
      styleContainer.animation =
        'flashing-shadow-step-three 10s ease 2s infinite';
    }
  }

  return (
    <Flex {...styleContainer}>
      <Flex {...styleInput} ref={ref}>
        <Flex {...stylePersonInfo}>
          <Img
            h={10}
            borderRadius='3xl'
            mr={3}
            overflow='hidden'
            src='/images/customer-avatar.jpg'
          />
          <Flex flexDirection='column' alignItems='start'>
            <Text as='span'>
              {t('how-work-become-customer.step3.user-name')}
            </Text>
            <Flex alignItems='center' fontSize='sm'>
              <Img h={3} src='/icons/StarBlack.svg' mr={1} overflow='hidden' />
              <Text as='span'>4.9</Text>
              <SeparatorVertical minH={4} h={4} mx={1.5} />
              <Img h={3} src='/icons/Like.svg' mr={1} overflow='hidden' />
              <Text as='span' mr={1.5}>
                8
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex {...styleInputMessageOne}>
          <Text as='span'>{t('how-work-become-customer.step3.message1')}</Text>
        </Flex>
        <Flex {...styleInputMessageTwo}>
          <Text as='span'>{t('how-work-become-customer.step3.message2')}</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HowWorkStepThree;
