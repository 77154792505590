import React from 'react';
import { Heading, Button, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import useModalPaddings from 'src/hooks/useModalPaddings';
import CreateTaskDescription from '../createTask/CreateTaskDescription';
import { useTaskStore } from '../../store/taskProvider';
import { emptySpaceRegExp } from '../../utils/regExp';

const styleBtn = {
  variant: 'primaryBlack',
  mb: 5,
  w: ['100%', '100%', 'auto'],
  'data-gtm': 'create-preview-order',
};

const CreateTaskModalContent = ({ jobType }) => {
  const { t } = useTranslation('common');
  const { setTask } = useTaskStore();
  const router = useRouter();
  const { register, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const orderSubmit = async taskData => {
    setTask(prev => ({
      ...prev,
      jobType: jobType || null,
      description: taskData?.description?.replace(emptySpaceRegExp, ' ').trim(),
    }));
    return routerPush(router, '/create-task');
  };
  const boxPaddings = useModalPaddings();

  return (
    <Flex flexDirection='column' {...boxPaddings}>
      <Heading
        data-testid='what-todo'
        as='fixLg'
        textAlign='center'
        size='1-2xl'
        mb={5}
      >
        {t('what-should-be-done')}
      </Heading>
      <CreateTaskDescription
        dataTestId='task-description-modal'
        register={register}
        errors={errors}
      />
      <Button {...styleBtn} onClick={handleSubmit(orderSubmit)}>
        {t('button.continue')}
      </Button>
    </Flex>
  );
};

export default CreateTaskModalContent;
