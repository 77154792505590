import React from 'react';
import { Box } from '@chakra-ui/react';
import { MIN_HEIGHT } from '../../common/constants';
import BackButton from '../../components/BackButton';

const MainPageLayout = ({ children, ...props }) => (
  <>
    <Box minH={MIN_HEIGHT} {...props}>
      {children}
      <BackButton />
    </Box>
  </>
);

export default MainPageLayout;
