import React from 'react';
import { Flex, Box, Text, Img } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ViewsIcon } from 'src/icons';
import useObserve from '../../hooks/useInView';

const styleContainer = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgColor: 'brand.secondaryBackground',
  borderRadius: '3xl',
  width: '100%',
  maxW: 81,
  h: 48,
  p: 4,
};

const styleInput = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'space-between',
  bgColor: 'white',
  boxShadow: '0px 4px 30px 0px #0000000d',
  borderRadius: 'lg',
  px: 5,
  py: 4,
  w: '100%',
  maxW: 66,
  h: 33,
};

const styleLimiter = {
  as: 'span',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxW: 40,
};

const styleViews = {
  as: 'div',
};
const styleResponses = {
  as: 'div',
};

const HowWorkStepTwo = ({ isMobile, title, jobTypeName }) => {
  const { t } = useTranslation('common');
  const { ref, inView } = useObserve();

  if (inView) {
    if (isMobile) {
      styleViews.animation = 'scroll-views 4s ease infinite';
      styleResponses.animation = 'scroll-responses 4s ease infinite';
      styleContainer.animation = 'flashing-shadow-step-two 4s ease infinite';
    } else {
      styleViews.animation = 'scroll-views 10s ease infinite';
      styleResponses.animation = 'scroll-responses 10s ease 0.5s infinite';
      styleContainer.animation = 'flashing-shadow-step-two 10s ease infinite';
    }
  }

  return (
    <Flex {...styleContainer}>
      <Flex {...styleInput} ref={ref}>
        <Flex justifyContent='space-between' fontWeight='semibold'>
          <Text {...styleLimiter}>{title}</Text>
          <Text as='span'>{t('how-work-become-customer.step2.price')}</Text>
        </Flex>
        <Flex
          justifyContent='space-between'
          color='typography.secondary'
          mb={2}
          fontSize='sm'
        >
          <Text {...styleLimiter}>{jobTypeName}</Text>
          <Flex alignItems='center'>
            <ViewsIcon h={4} color='gray' mr={1.5} />
            <Box w={4} h={5} overflow='hidden'>
              <Text {...styleViews}>
                0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex>
          <Flex alignItems='center' fontWeight='semibold'>
            <Img h={4.5} src='/icons/People.svg' mr={1.5} overflow='hidden' />
            <Flex alignItems='center'>
              <Box w={4} h={6} overflow='hidden' color='typography.primary'>
                <Text {...styleResponses}>0 1 2 3 4 5 6 7 8</Text>
              </Box>
              <Text as='span' fontSize='sm'>
                {t('how-work-become-customer.step2.responded')}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default HowWorkStepTwo;
