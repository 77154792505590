import React, { useContext, useState } from 'react';
import { Heading, Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import DefaultContainer from '../../layouts/DefaultContainer';
import { howWorkData } from './howWorkData';
import HowWorkStepOne from './HowWorkStepOne.jsx';
import HowWorkStepTwo from './HowWorkStepTwo.jsx';
import HowWorkStepThree from './HowWorkStepThree.jsx';
import HowItWorkCard from './HowItWorkCard.jsx';
import { commonStore } from '../../store/storeProvider';
import Modal from '../../components/Modal';
import CreateTaskModalContent from './CreateTaskModalContent.jsx';

const HowWorkBecomeCustomer = ({ taskTitle, jobItem, as }) => {
  const { t } = useTranslation(['common']);
  const router = useRouter();
  const items = howWorkData.howWorkType;
  const {
    common: {
      ui: { isMobile },
    },
  } = useContext(commonStore);
  const [isOpen, setOpen] = useState(false);
  const titleTask =
    taskTitle ||
    jobItem?.orderTitle ||
    t('how-work-become-customer.title-task');
  const jobTypeName = jobItem?.orderTitle
    ? jobItem?.name
    : t('how-work-become-customer.jobType-title-task');
  return (
    <DefaultContainer
      data-testid='how-it-work'
      id='howItWork'
      flexDirection='column'
      alignItems='center'
      container={{ as: 'section' }}
    >
      <Modal isOpen={isOpen} onClose={() => setOpen(false)}>
        <CreateTaskModalContent router={router} jobType={jobItem} />
      </Modal>
      <Heading as={as || 'h4'} size='2-4xl'>
        {t('how-it-works')}
      </Heading>
      <Flex
        flexDirection={['column', 'column', 'row']}
        mt={[0, 0, 12]}
        mb={[0, 0, 16]}
      >
        <HowItWorkCard item={items[0]}>
          <HowWorkStepOne
            isMobile={isMobile}
            title={titleTask}
            jobTypeName={jobTypeName}
            setOpen={setOpen}
          />
        </HowItWorkCard>
        <HowItWorkCard item={items[1]}>
          <HowWorkStepTwo
            isMobile={isMobile}
            title={titleTask}
            jobTypeName={jobTypeName}
          />
        </HowItWorkCard>
        <HowItWorkCard item={items[2]}>
          <HowWorkStepThree isMobile={isMobile} />
        </HowItWorkCard>
      </Flex>
    </DefaultContainer>
  );
};

export default HowWorkBecomeCustomer;
