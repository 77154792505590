import React from 'react';
import { Flex, Text, Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import NextLink from '../../components/NextLink';

const HowItWorkCard = ({ item, children }) => {
  const { t } = useTranslation(['common']);
  return (
    <Flex
      flexDir={['column', 'column', 'column-reverse']}
      alignItems='center'
      justifyContent='start'
      textAlign='center'
      min-width={50}
      min-height={['auto', 'auto', '380px']}
      width={['auto', 'auto', '33%']}
      mx={[2, 2, 2.5]}
      mt={[7, 7, 0]}
      mb={[5, 5, 0]}
      key={item.id}
    >
      <Box
        mt={[0, 0, 5]}
        mb={[4, 4, 0]}
        maxW={114}
        textAlign={['center', 'center', 'start']}
        mx={4}
      >
        {item?.href ? (
          <NextLink href={item.href}>
            <Heading as='span' size='md'>
              {t(item.name)}
            </Heading>
          </NextLink>
        ) : (
          <Heading as='span' size='md'>
            {t(item.name)}
          </Heading>
        )}
        <Text fontWeight='normal' color='typography.secondary' mt={2}>
          {t(item.description)}
        </Text>
      </Box>
      {children}
    </Flex>
  );
};

export default HowItWorkCard;
