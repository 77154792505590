/* eslint-disable import/prefer-default-export */
import { useInView } from 'react-intersection-observer';
import dynamic from 'next/dynamic';
import { useState } from 'react';

const useObserve = () => {
  const [loading, setLoading] = useState(false);
  const { ref, inView, entry } = useInView();
  if (
    typeof window !== 'undefined' &&
    typeof window.IntersectionObserver === 'undefined'
  ) {
    setLoading(true);
    dynamic(() => import('intersection-observer')).then(() =>
      setLoading(false),
    );
  }
  return {
    ref,
    inView,
    entry,
    loading,
  };
};
export default useObserve;
