import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import useObserve from '../../hooks/useInView';

const styleContainer = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  bgColor: 'brand.secondaryBackground',
  borderRadius: '3xl',
  width: '100%',
  maxW: 81,
  h: 48,
  p: 4,
};

const styleInput = {
  flexDirection: 'column',
  justifyContent: 'center',
  bgColor: 'white',
  boxShadow: '0px 4px 30px 0px #0000000d',
  borderRadius: 'lg',
  w: '100%',
  h: 13,
  maxW: 66,
};

const printedText = {
  as: 'span',
  textAlign: 'start',
  ml: 5,
  w: '27ch',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const printedDefaultText = {
  as: 'span',
  color: 'typography.secondary',
  textAlign: 'start',
  ml: 5,
  position: 'absolute',
};

const styleJobType = {
  as: 'span',
  fontWeight: 'semibold',
  ml: 5,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  w: 50,
};

const HowWorkStepOne = ({ isMobile, title, jobTypeName, setOpen }) => {
  const { t } = useTranslation('common');
  const { ref, inView } = useObserve();

  if (inView) {
    if (isMobile) {
      printedText.animation = 'printed-text 5s steps(27) infinite';
      printedDefaultText.animation = 'disappearance 5s ease infinite';
      styleContainer.animation = 'flashing-shadow-step-one 5s ease infinite';
    } else {
      printedText.animation = 'printed-text 10s steps(27) infinite';
      printedDefaultText.animation = 'disappearance 10s ease infinite';
      styleContainer.animation = 'flashing-shadow-step-one 10s ease infinite';
    }
  }

  return (
    <Flex {...styleContainer}>
      <Flex
        data-testid='create-task-input'
        {...styleInput}
        mb={7}
        ref={ref}
        cursor='pointer'
        overflow='hidden'
        onClick={() => setOpen(true)}
      >
        <Text {...printedDefaultText}>
          {t('how-work-become-customer.step1.placeholder')}
        </Text>
        <Text {...printedText}>{title}</Text>
      </Flex>
      <Flex {...styleInput} cursor='pointer' onClick={() => setOpen(true)}>
        <Text {...styleJobType} fontWeight='semibold'>
          {jobTypeName}
        </Text>
      </Flex>
    </Flex>
  );
};

export default HowWorkStepOne;
