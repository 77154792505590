import { numbers } from "src/store/common/constants";

/* eslint-disable import/prefer-default-export */
export const howWorkData = {
  howWorkType: [
    {
      id: '458dc5eb-a0a2-4495-85a0-b11c5537afcc',
      name: 'how-work-become-customer.step1.name',
      description: 'how-work-become-customer.step1.description',
      image: 'createTask.svg',
      href: '/create-task',
      step: numbers.ONE,
    },
    {
      id: '458649eb-a0a2-4495-85a0-b11c5537afcc',
      name: 'how-work-become-customer.step2.name',
      description: 'how-work-become-customer.step2.description',
      image: 'allOffer.svg',
      step: numbers.TWO,
    },
    {
      id: '458dc5eb-a0a2-4495-85a0-fo54354h5u6543',
      name: 'how-work-become-customer.step3.name',
      description: 'how-work-become-customer.step3.description',
      image: 'getOffer.svg',
      step: numbers.THREE,
    },
  ],
};
