import React from 'react';
import { Box, Textarea } from '@chakra-ui/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'next-i18next';
import { descriptionRegExp } from '../../../utils/regExp';

const CreateTaskDescription = ({
  dataTestId,
  register,
  errors,
  err,
  placeholder,
  ...rest
}) => {
  const { t } = useTranslation('common');
  const textareaPlaceholder = placeholder || t('tell-me-more-about-the-job');
  return (
    <Box mb={[5, 5, 7]} data-testid='description' w='100%' {...rest}>
      <Textarea
        data-testid={dataTestId}
        name='description'
        variant={
          errors.description || err?.code === 'description'
            ? 'error'
            : 'outline'
        }
        h={28}
        bg='white'
        fontWeight='normal'
        placeholder={textareaPlaceholder}
        resize='none'
        verticalAlign='top'
        ref={register({
          required: t('obligatory-field'),
          pattern: {
            value: new RegExp(`${descriptionRegExp}`),
            message: t('invalid-special-characters', { characters: ' <>' }),
          },
          minLength: {
            value: 2,
            message: t('at-least-count-characters', { count: 2 }),
          },
          maxLength: {
            value: 1500,
            message: t('no-more-than-count-characters', { count: 1500 }),
          },
        })}
      />
      <ErrorMessage
        errors={errors}
        name='description'
        render={({ message }) => (
          <Box pos='relative'>
            <Box data-testid='description-error' fontSize='xs' position='absolute'>
              {message}
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

export default CreateTaskDescription;
